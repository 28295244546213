import PageRefresh from "./PageRefresh";
export default class app {
	constructor(refresh) {
		let bd = document.getElementsByTagName("html")[0];
		let app = document.querySelector(".app");
		this.refresh = refresh.active_refresh;
		this.refreshTime = refresh.refresh_time;
		if (app) {
			let page = app.dataset.page;
			this.bd = bd;
			this.page = page;
		}
	}
	render() {
		/*
		 * Common functions
		 */
		// Mobile menu
		this.openMobileMenu();
		this.initHeaderSearchBar();
		// Check premium meta-kickers length
		let meta_kickers = document.querySelectorAll(".js-meta-kicker-plus");
		this.initCheckMetaKickersPlus(meta_kickers);
		this.pageRefresh = new PageRefresh(this.refreshTime);
		if (this.refresh) {
			console.log("PageRefresh")
			this.pageRefresh.load();
		}
		/*
		 * Page templates
		 */
		if (document.querySelector(".wrap-row")) {
			let wrap_row = document.querySelectorAll(".wrap-row");
			window.onload = this.inlineWrapRow(wrap_row);
			window.addEventListener("resize", (e) => {
				let wrap_row = document.querySelectorAll(".wrap-row");
				this.inlineWrapRow(wrap_row);
			});
		}
		if (document.querySelector(".js-masthead")) {
			let masthead = document.querySelector(".js-masthead");
			// let yStart = 0;
			// let yEnd = 0;
			setTimeout(() => {
				if (window.innerWidth < 768) {
					masthead.classList.add("draggable");
					if (masthead.classList.contains("draggable")) {
						let close = document.createElement("div");
						close.classList.add("close-masthead");
						masthead.appendChild(close);
						close.addEventListener("click", (e) => {
							masthead.classList.add("not-sticky");
						});
					}
					// if (masthead.classList.contains("draggable")) {
					//   masthead.addEventListener("touchstart", (e) => {
					//     yStart = e.changedTouches[0].screenY;
					//   }, false);
					//   masthead.addEventListener("touchend", (e) => {
					//     yEnd = e.changedTouches[0].screenY;
					//     if (yEnd < yStart) {
					//       masthead.classList.add("not-sticky");
					//     }
					//   }, false);
					// }
				}
			}, 4500);
		}
		if (this.page === "home") {
			console.log("home");
			let masthead = document.querySelector(".js-masthead");
			window.onload = this.checkMastHead(masthead);
			// DA RIVEDERE
			if (document.querySelector(".js-latest-post")) {
				const intro = document.querySelector(".js-intro-posts");
				this.evenIntroSpacings(intro);
				window.addEventListener("resize", (e) => {
					const intro = document.querySelector(".js-intro-posts");
					this.evenIntroSpacings(intro);
				});
			}
			let featured_post_sliders = document.querySelectorAll(".js-featured-posts-slider");
			this.initFeaturedPostSliders(featured_post_sliders);
			// DA RIVEDERE

			let featured_posts = document.querySelectorAll(".js-featured-posts");
			this.checkFPSlidersColorContrast(featured_posts);

			let related_posts_slider = document.querySelector(".js-related-posts-slider");
			this.initRelatedPostsSlider(related_posts_slider);
		}

		if (this.page === "single-article" || this.page === "single-article-premium") {
			// check if cat-badges are in main menu-nav
			if (document.querySelector(".js-a-tags")) {
				this.checkCatBadges();
			}

			let masthead = document.querySelector(".js-masthead");
			window.onload = this.checkMastHead(masthead);

			let article_tags = document.querySelector(".js-a-tags");
			this.positionArticleBadges(article_tags);

			window.onload = this.reduceArticleHeaderContentGap();

			let keypoints_cont = document.querySelector(".js-keypoints");
			window.onload = this.wrapArticleKeypoints(keypoints_cont);
			if (keypoints_cont) {
				let keypoints_list = keypoints_cont.querySelectorAll(".js-keypoint a");
				if (keypoints_list) {
					Array.from(keypoints_list).map((item) => {
						item.addEventListener("click", (event) => {
							event.preventDefault();
							let target = event.target;
							if (target.tagName === "H5") {
								target = event.target.parentNode;
							}
							const anchor = target.getAttribute("href").substring(1);
							$("html").animate({
								scrollTop: $(`[name=${anchor}]`).offset().top - 126,
							});
						});
					});
				}
			}

			// check adv single article
			let aside = ".aside-content";
			if (document.querySelector(aside)) {
				aside = document.querySelector(aside);
				if (aside.hasChildNodes()) {
					let a_childs = aside.children;
					if (a_childs.length > 1 && window.innerWidth < 768) {
						let art_wrap = document.querySelector(".js-article");
						let art = art_wrap.querySelector(".article-content");
						let art_childs = art.children;

						// get middles
						let blocks_mi = Math.round(art_childs.length / 2);

						if (a_childs[1]) {
							// clone ad
							let cloned_ad = a_childs[1].cloneNode(true);
							cloned_ad.classList.add("ad-cta");
							cloned_ad.style.display = "block";
							cloned_ad.style.maxWidth = "300px";
							cloned_ad.style.marginRight = "auto";
							cloned_ad.style.marginLeft = "auto";
							// remove current

							// append  cloned
							if (blocks_mi > 1) {
								if (art_childs[art_childs.length - 1].classList.contains("ad-cta")) {
									cloned_ad.classList.remove("ad-cta");
								}
								art_childs[blocks_mi - 1].after(cloned_ad);
							} else {
								if (art.querySelector(".ad-cta")) {
									cloned_ad.classList.remove("ad-cta");
								}
								art.append(cloned_ad);
							}
						}

						if (a_childs[2]) {
							// clone ad
							let cloned_ad = a_childs[2].cloneNode(true);
							cloned_ad.classList.add("ad-cta");
							cloned_ad.style.display = "block";
							cloned_ad.style.maxWidth = "300px";
							cloned_ad.style.marginRight = "auto";
							cloned_ad.style.marginLeft = "auto";
							// remove current

							// append  cloned
							if (art_childs[art_childs.length - 1].classList.contains("ad-cta")) {
								cloned_ad.classList.remove("ad-cta");
							}
							art_childs[art_childs.length - 1].after(cloned_ad);
						}

						a_childs[2].remove();
						a_childs[1].remove();
					}
				}
			}
			// check adv single article

			let galleries = document.querySelectorAll(".js-gallery-slider");
			this.initGalleriesSliders(galleries);

			let attachments_cont = document.querySelector(".js-attachments-cont");
			if (attachments_cont) {
				let article_attachments_slider = attachments_cont.querySelector(".js-attachments-swiper");
				this.initAttachmentsSlider(article_attachments_slider);
				this.initCheckAttachmentSliderLength(attachments_cont);
			}
			let related_posts_slider = document.querySelector(".js-related-posts-slider");
			this.initRelatedPostsSlider(related_posts_slider);

			let custom_tables = ".js-custom-table";
			if (document.querySelector(custom_tables)) {
				setTimeout(() => {
					this.initCustomTables(custom_tables);
				}, 1000);
			}
		}
		if (this.page === "single-article-premium") {
			let btn_unlock = document.querySelector(".btn-cta-unlock");
			let cta_membership = document.querySelector(".cta-membership");
			let cm_plans = cta_membership.querySelector(".cm-plans");
			if (cm_plans.classList.contains("signup-free")) {
				btn_unlock.href = "/tag/registered/";
			} else if (cm_plans.classList.contains("pay-to-read")) {
				btn_unlock.href = "/tag/paywall/";
			} else {
				btn_unlock.href = "/tag/pay-to-read/";
			}
		} else {
			//...
		}
	}

	/*
	 * Common functions
	 */
	// Mobile menu
	returnMobileMenuElems() {
		let body = document.querySelector("body");
		let burger = body.querySelector(".js-burger");
		let mobile_nav = body.querySelector(".js-mobile-nav");
		let mobile_nav_overlay = mobile_nav.querySelector(".js-nav-overlay");
		let nav_li = mobile_nav.querySelectorAll("li");
		return { body, burger, mobile_nav, mobile_nav_overlay, nav_li };
	}
	openMobileMenu() {
		let elems = this.returnMobileMenuElems();
		let burger = elems.burger;
		let mobile_nav = elems.mobile_nav;
		if (burger && mobile_nav) {
			burger.addEventListener("click", this.toggleBurger.bind(this));
		}
	}
	toggleBurger() {
		let elems = this.returnMobileMenuElems();
		let burger = elems.burger;
		burger.classList.toggle("clicked");
		this.showMobileNav();
	}
	showMobileNav() {
		let elems = this.returnMobileMenuElems();
		let body = elems.body;
		let mobile_nav = elems.mobile_nav;
		let mobile_nav_overlay = elems.mobile_nav_overlay;
		mobile_nav.classList.toggle("opened");
		body.classList.toggle("no-scroll");
		mobile_nav_overlay.addEventListener("click", this.closeMobileMenu.bind(this));
	}
	closeMobileMenu() {
		let elems = this.returnMobileMenuElems();
		let body = elems.body;
		let burger = elems.burger;
		let mobile_nav = elems.mobile_nav;
		body.classList.remove("no-scroll");
		burger.classList.remove("clicked");
		mobile_nav.classList.remove("opened");
	}
	// Searchbar in header-menu
	initHeaderSearchBar() {
		let searchbar_btn = document.querySelector(".js-search-toggle");
		if (searchbar_btn) {
			searchbar_btn.addEventListener("click", this.toggleHeaderSearchBar.bind(this));
		}
	}
	toggleHeaderSearchBar() {
		let searchbar_btn = document.querySelector(".js-search-toggle");
		let search_bar = searchbar_btn.previousElementSibling;
		search_bar.classList.toggle("opened");
	}

	// Check premium meta-kickers length
	initCheckMetaKickersPlus(meta_kickers) {
		if (meta_kickers) {
			let mk_size = meta_kickers.length;
			for (let i = 0; i < mk_size; i++) {
				let mk_char_size = meta_kickers[i].innerText.length;
				if (mk_char_size < 1) {
					meta_kickers[i].classList.add("no-kicker");
				}
			}
		}
	}

	/*
	 * Page templates
	 */
	// Homepage
	// DA RIVEDERE
	evenIntroSpacings() {
		let intro = document.querySelector(".js-intro-posts");
		if (intro) {
			this.reduceIntroSpacings(intro);
		}
	}
	reduceIntroSpacings(intro) {
		let main_post = intro.querySelector(".js-latest-post");
		let posts_wrap_row = intro.querySelector(".js-wrap-row");
		let side_posts = intro.querySelectorAll(".js-main-posts-sidebar");
		let sp_size = side_posts.length;
		let sp_total_height = 0;
		for (let i = 0; i < sp_size; i++) {
			sp_total_height += side_posts[i].getBoundingClientRect().height;
		}
		if (window.innerWidth > 767) {
			if (main_post.hasAttribute("style")) {
				main_post.removeAttribute("style");
			}
			let main_post_h = main_post.getBoundingClientRect().height;
			if (sp_total_height > main_post_h) {
				main_post.style.minHeight = "calc(" + sp_total_height + "px" + " + " + "3rem)";
				posts_wrap_row.style.marginTop = "calc(-" + sp_total_height + "px" + " - " + "3rem" + " + " + main_post_h + "px)";
				main_post.classList.remove("has-bb");
				main_post.querySelector(".post-excerpt").classList.add("has-bb");
			}
			// main_post.style.minHeight = "calc(" + sp_total_height + "px" + " + " + "3rem)";
			// posts_wrap_row.style.marginTop = "calc(-" + sp_total_height + "px" + " - " + "3rem" + " + " + main_post_h + "px)";
			// main_post.classList.remove("has-bb");
			// main_post.querySelector(".post-excerpt").classList.add("has-bb");
		} else {
			main_post.style.minHeight = "unset";
			posts_wrap_row.style.marginTop = "unset";
			main_post.querySelector(".post-excerpt").classList.remove("has-bb");
			if (!main_post.classList.contains("has-bb")) {
				main_post.classList.add("has-bb");
			}
		}
	}

	initFeaturedPostSliders(sliders) {
		if (sliders) {
			let fps_size = sliders.length;
			let btn_next = document.querySelectorAll(".swiper-button-next");
			let btn_prev = document.querySelectorAll(".swiper-button-prev");
			for (let i = 0; i < fps_size; i++) {
				btn_next[i].classList.add("fp-button-next-" + i);
				btn_prev[i].classList.add("fp-button-prev-" + i);
				sliders[i].classList.add("js-featured-posts-slider-" + i);
				let fps_slider = new Swiper(`.js-featured-posts-slider-${i}`, {
					slidesPerView: 1,
					spaceBetween: 12,
					loop: false,
					navigation: {
						nextEl: ".fp-button-next-" + i,
						prevEl: ".fp-button-prev-" + i,
					},
					breakpoints: {
						660: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 3,
						},
					},
				});
			}
		}
	}
	// Check featured posts color contrast between bg-color and txt-color
	checkFPSlidersColorContrast(featured_posts) {
		if (featured_posts) {
			let fp_size = featured_posts.length;
			for (let i = 0; i < fp_size; i++) {
				let fp_wrapper = featured_posts[i].querySelector(".js-fp-wrapper");
				let bg_color = fp_wrapper.style.backgroundColor;
				let r, g, b, hsp;
				let fpw_brightness;
				bg_color = bg_color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
				r = bg_color[1];
				g = bg_color[2];
				b = bg_color[3];
				hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
				if (hsp > 127.5) {
					fpw_brightness = "light";
				} else {
					fpw_brightness = "dark";
				}
				if (fpw_brightness === "dark") {
					fp_wrapper.classList.add("has-light-text");
				} else {
					fp_wrapper.classList.add("has-dark-text");
				}
			}
		}
	}
	// Single article
	checkCatBadges() {
		const catVoices = document.querySelector(".js-a-tags").children;
		const navContainer = document.querySelector(".menu-header-menu-container");
		const navVoices = navContainer.querySelectorAll("a");
		if (catVoices.length > 0) {
			Array.from(catVoices).forEach((voice) => {
				const voiceSlug = voice.textContent.toLowerCase().replace(/\s/g, "");
				if (navVoices.length > 0) {
					navVoices.forEach((navVoice) => {
						const navVoiceSlug = navVoice.textContent.toLowerCase().replace(/\s/g, "");
						if (navVoiceSlug === voiceSlug) {
							voice.setAttribute("href", `/${navVoiceSlug}`);
						}
					});
				}
			});
		}
	}

	checkMastHead(masthead) {
		let m_size = masthead.children.length;
		let cf_adzone = masthead.querySelector(".cf-adZone");
		if (m_size > 0) {
			masthead.classList.remove("hidden");
		}
		if (cf_adzone) {
			masthead.classList.add("hidden");
		}
	}
	// Position article badges
	positionArticleBadges(tags) {
		if (tags) {
			if (tags.hasChildNodes()) {
				let t_size = tags.children.length;
				if (t_size > 2) {
					tags.classList.add("a-f-start");
				}
			}
		}
	}
	// Reduce gap of article content in article intro type title dx
	reduceArticleHeaderContentGap() {
		this.reduceContentGap();
		window.onresize = this.reduceContentGap.bind(this);
	}
	reduceContentGap() {
		let window_w = window.innerWidth;
		let article = document.querySelector(".js-article");
		let a_header = document.querySelector(".js-a-header-wrap");
		if (article && a_header) {
			let a_thumb = a_header.querySelector(".js-thumb-has-td");
			let a_txt = a_header.querySelector(".js-ai-txt-dx");
			if (window_w > 767) {
				let a_header_h = a_header.offsetHeight;
				let a_thumb_h = a_thumb.offsetHeight;
				let a_txt_h = a_txt.offsetHeight;
				if (a_txt_h > a_thumb_h) {
					let margin_top = a_header_h - a_thumb_h;
					article.style.marginTop = -margin_top + "px";
				}
			}
		}
	}
	// Keypoints
	wrapArticleKeypoints(keypoints_cont) {
		if (keypoints_cont) {
			if (keypoints_cont.hasChildNodes()) {
				let k_size = keypoints_cont.children.length;
				if (k_size > 5) {
					keypoints_cont.classList.add("col-count-2");
					//keypoints_cont.style.maxHeight = 18 * (k_size - 1) + "px";
				}
			}
		}
	}
	// Drop caps
	// dropCap(paragraph, drop_cap) {
	//   if (paragraph) {
	//     paragraph.classList.add("has-drop-cap");
	//     let first_p = paragraph.querySelector("p");
	//     let first_letter = paragraph.innerText.charAt(0);
	//     drop_cap.innerHTML = first_letter;
	//     first_p.classList.add("has-hidden-first-letter");
	//   }
	// }
	// Galleries
	initGalleriesSliders(gallery_nodes) {
		if (gallery_nodes) {
			let g_size = gallery_nodes.length;
			for (let i = 0; i < g_size; i++) {
				let g_slider = new Swiper(gallery_nodes[i], {
					slidesPerView: 1,
					autoplay: true,
					navigation: {
						nextEl: ".gallery-button-next",
						prevEl: ".gallery-button-prev",
					},
				});
			}
		}
	}
	// Article attachments
	initAttachmentsSlider(slider) {
		if (slider) {
			let a_slider = new Swiper(slider, {
				slidesPerView: 1,
				spaceBetween: 12,
				navigation: {
					nextEl: ".att-button-next",
					prevEl: ".att-button-prev",
				},
				breakpoints: {
					// 660: {
					//   slidesPerView: 3
					// },
					768: {
						slidesPerView: "auto",
						slidesPerGroup: 4,
					},
				},
			});
		}
	}

	initCheckAttachmentSliderLength(attachments_cont) {
		if (attachments_cont) {
			let slides = attachments_cont.querySelectorAll(".swiper-slide");
			let slides_size = slides.length;
			if (slides_size < 2) {
				attachments_cont.classList.add("has-no-padding");
			}
		}
	}

	initCustomTables(custom_tables) {
		custom_tables = document.querySelectorAll(custom_tables);
		for (let i = 0; i < custom_tables.length; i++) {
			let rows = custom_tables[i].querySelectorAll(".t-row");
			let header_row = rows[0];
			let hr_cols = header_row.querySelectorAll(".js-t-col");
			let hr_cols_txts = new Array();
			for (let j = 0; j < hr_cols.length; j++) {
				hr_cols_txts.push(hr_cols[j].innerText);
			}
			for (let j = 1; j < rows.length; j++) {
				let cols = rows[j].querySelectorAll(".js-t-col");
				for (let k = 0; k < cols.length; k++) {
					cols[k].setAttribute("data-col", hr_cols_txts[k]);
				}
				rows[j].addEventListener("click", (e) => {
					for (let k = 0; k < cols.length; k++) {
						cols[k].classList.toggle("open");
					}
				});
			}
		}
	}

	// Init related posts slider
	initRelatedPostsSlider(slider) {
		if (slider) {
			let rps_slider = new Swiper(slider, {
				slidesPerView: 1,
				spaceBetween: 12,
				navigation: {
					nextEl: ".rel-button-next",
					prevEl: ".rel-button-prev",
				},
				breakpoints: {
					660: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
				},
			});
		}
	}
	// Wrap posts with different heights
	inlineWrapRow(wrap_row) {
		if (wrap_row) {
			if (window.innerWidth >= 768) {
				for (let i = 0; i < wrap_row.length; i++) {
					let min_height = new Array();
					let post_titles = wrap_row[i].querySelectorAll(".post-title");
					min_height.push(0);
					for (let j = 0; j < post_titles.length; j++) {
						let title_h = post_titles[j].offsetHeight;
						if (title_h > min_height[0]) {
							min_height.push(title_h);
							min_height.shift();
						}
					}
					let minArtHeight = [0];
					for (let j = 0; j < post_titles.length; j++) {
						const artHeight = (post_titles[j].closest(".post").style.minHeight =
							min_height[0] +
							post_titles[j].closest(".post").querySelector(".post-thumb").offsetHeight +
							parseInt(window.getComputedStyle(post_titles[j].closest(".post").querySelector(".post-thumb"), null).getPropertyValue("margin-bottom")));
						minArtHeight.push(artHeight);
						if (minArtHeight[0] < artHeight) {
							minArtHeight.push(artHeight);
							minArtHeight.shift();
						}
						post_titles[j].closest(".post").style.height = Math.round(minArtHeight[0] + 10) + "px";
					}
				}
			} else {
				for (let i = 0; i < wrap_row.length; i++) {
					const articles = wrap_row[i].querySelectorAll(".post");
					for (let j = 0; j < articles.length; j++) {
						if (articles[j].hasAttribute("style")) {
							articles[j].removeAttribute("style");
							articles[j].querySelector(".post-title").removeAttribute("style");
						}
					}
				}
			}
		}
	}
}
